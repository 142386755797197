.h-0 { height: 0% ;}
.vh-3 { height: 3vh; }
.vh-5 { height: 5vh; }
.vh-7 { height: 7vh; }
.vh-8 { height: 8vh; }
.vh-10 { height: 10vh; }
.vh-11 { height: 11vh; }
.vh-15 { height: 15vh; }
.vh-20 { height: 20vh; }
.vh-25 { height: 25vh; }
.vh-30 {  height:  30vh; }
.vh-35 {  height:  35vh; }
.vh-40 {  height:  40vh; }
.vh-43 {  height:  43vh; }
.vh-50 {  height:  50vh; }
.vh-55 {  height:  55vh; }
.vh-60 {  height:  60vh; }
.vh-65 {  height:  65vh; }
.vh-75 {  height:  75vh; }
.vh-70 {  height:  70vh; }
.vh-80 {  height:  80vh; }
.vh-85 {  height:  85vh; }
.vh-95 {  height:  95vh; }

.min-vh-5 { min-height: 5vh; }
.min-vh-6 { min-height: 6vh; }
.min-h-80px { min-height: 80px; }
.min-h-100px { min-height: 100px; }
.min-h-150px { min-height: 150px; }
.min-h-200px { min-height: 200px; }
.min-h-240px { min-height: 240px; }
.min-h-300px { min-height: 300px; }
.min-h-80 { min-height: 80%; }
.min-h-13 { min-height: 13rem; }
.min-h-15 { min-height: 15rem; }
.min-h-17 { min-height: 17rem; }

.max-h-none { max-height: none; }
.max-h-30 { max-height: 30%; }
.max-h-40 { max-height: 40%; }
.max-h-50 { max-height: 50%; }
.max-h-60 { max-height: 60%; }
.max-h-auto { max-height: auto; }




.h-50px { height: 50px; }
.h-6 { height: 6%; }
.h-90 { height: 90%; }
.h-60 { height: 60%; }


/*
  ipad 48 rem
  ipad pro 64 rem
  iphone x 23.6 rem
  iphone plus 25.9
  Pixel 2XL 25.7
  Galaxy s5 22.5
  iphone 5 20
*/

@media screen and (min-width: 48em) {
  .h-a-ns { height: auto; }
  .vh-5-ns { height: 5vh; }
  .vh-10-ns { height: 10vh; }
  .vh-15-ns { height: 15vh; }
  .vh-20-ns { height: 20vh; }
  .vh-35-ns { height: 35vh; }
  .vh-40-ns { height: 40vh; }
  .vh-50-ns { height: 50vh; }
  .vh-55-ns { height: 55vh; }
  .vh-60-ns { height: 60vh; }
  .vh-65-ns { height: 65vh; }
  .vh-75-ns { height: 75vh; }
  .vh-85-ns { height: 85vh; }
  .vh-95-ns { height: 95vh; }
  .h-50px-ns { height: 50px; }
  .max-h-800px-ns { max-height: 800px; }
  .max-h-none-ns { max-height: none; }
  .max-h-100-ns { max-height: 100%; }
  .h-600px-ns { height: 600px; }
  .min-vh-8-ns { min-height: 8vh; }
  .min-vh-9-ns { min-height: 9vh; }
  .min-h-14-ns { min-height: 14rem; }
  .min-h-15-ns { min-height: 15rem; }
  .min-h-17-ns { min-height: 17rem; }
}

@media screen and (min-width: 48em) and (max-width: 64em) {
  .vh-10-m { height: 10vh; }
  .vh-20-m { height: 20vh; }
  .vh-35-m { height: 35vh; }
  .vh-40-m { height: 40vh; }
  .vh-50-m { height: 50vh; }
  .vh-55-m { height: 55vh; }
  .vh-60-m { height: 60vh; }
  .vh-65-m { height: 65vh; }
  .vh-75-m { height: 75vh; }
  .vh-85-m { height: 85vh; }
  .vh-95-m { height: 95vh; }
}

@media screen and (min-width: 64em) {
  .vh-8-l { height: 8vh; }
  .vh-10-l { height: 10vh; }
  .vh-15-l { height: 15vh; }
  .vh-20-l { height: 20vh; }
  .vh-35-l { height: 35vh; }
  .vh-40-l { height: 40vh; }
  .vh-50-l { height: 50vh; }
  .vh-55-l { height: 55vh; }
  .vh-60-l { height: 60vh; }
  .vh-65-l { height: 65vh; }
  .vh-75-l { height: 75vh; }
  .vh-85-l { height: 85vh; }
  .vh-95-l { height: 95vh; }
  .max-h-800px-l { max-height: 800px; }
  .max-h-100-l { max-height: 100% }
  .min-h-25-l { min-height: 25rem; }
  .min-h-23-l { min-height: 23rem; }
}








