.w-4 { width: 4%; }
.w-5 { width: 5%; }
.w-10 { width: 10%; }
.w-15 { width: 15%; } 
.w-19 { width: 19%; } 
.w-20 { width: 20%; }
.w-23 { width: 23%; }
.w-26 { width: 26%; }
.w-32 { width: 32%; }
.w-35 { width: 35%; }
.w-43 { width: 43%; }
.w-47 { width: 47%; }
.w-48 { width: 48%; }
.w-49 { width: 49%; }
.w-95 { width: 95%; }
.w-300px { width: 300px;}
.min-w-0 { min-width: 0;}
.min-w-75 { min-width: 75%;}
.min-w-900px { min-width: 900px;}
.max-w-90 { max-width: 90% }
.max-w-80 { max-width: 80% }
.max-w-70 { max-width: 70% }
.max-w-60 { max-width: 60% }
.max-w-50 { max-width: 50% }
.max-w-40 { max-width: 40% }
.max-w-30 { max-width: 30% }
.max-w-20 { max-width: 20% }

.w6 { width: 32rem }
.w7 { width: 64rem }

@media screen and (min-width: 23em){
  .max-w-40-nrs { max-width: 40% }
}

@media screen and (min-width: 48em){
  .min-w-0-ns { min-width: 0;}
  .min-w-900px-ns { min-width: 900px;}
  .max-w-none-ns { max-width: none }
  .max-w-44-ns { max-width: 44% }
  .max-w-100-ns { max-width: 100% }
  .w-auto-ns { width: auto }
  
  .w-32-ns { width: 32%; }
  .w-25-ns { width: 25%; }
  .w-47-ns { width: 47%; }
}

@media screen and (min-width: 48em) and (max-width: 64em){
  .w-49-m { width: 49%; }
  .w-47-m { width: 47%; }
}

@media screen and (min-width: 64em) {
  .min-w-25-l { min-width: 25%;}
  .w-30-l { width: 30%; }
  .w-32-l { width: 32%; }
  .w-47-l { width: 47%; }
  .w-23-l { width: 23%; }
}

@media screen and (min-width: 75em) {
  .w1-xl {      width: 1rem; }
  .w2-xl {      width: 2rem; }
  .w3-xl {      width: 4rem; }
  .w4-xl {      width: 8rem; }
  .w5-xl {      width: 16rem; }
  .w-5-xl {     width:  5%; }
  .w-7-xl {     width:  7%; }
  .w-10-xl {    width:  10%; }
  .w-20-xl {    width:  20%; }
  .w-25-xl {    width:  25%; }
  .w-30-xl {    width:  30%; }
  .w-33-xl {    width:  33%; }
  .w-34-xl {    width:  34%; }
  .w-40-xl {    width:  40%; }
  .w-47-xl {    width:  47%; }
  .w-50-xl {    width:  50%; }
  .w-60-xl {    width:  60%; }
  .w-70-xl {    width:  70%; }
  .w-75-xl {    width:  75%; }
  .w-80-xl {    width:  80%; }
  .w-90-xl {    width:  90%; }
  .w-100-xl {   width: 100%; }
  .w-third-xl { width: calc(100% / 3); }
  .w-two-thirds-xl { width: calc(100% / 1.5); }
  .w-auto-xl {    width: auto; }
  .min-w-10-xl { min-width: 10%;}
  .max-w-10-xl { max-width: 10%;}
  
}


@media screen and (min-width: 100em) {
  .w-30-xxl { width: 30%; }
}



@media screen and (min-width: 162em) {
  .w-33-xxxl { width: 33%; }
}


