.font-adjust{
  font-size: 1rem 
}

.f0-5r { font-size: 0.5em }
.f0-75r { font-size: 0.75em }
.f1r { font-size: 1em }
.f1-25r { font-size: 1.25em }
.f1-5r { font-size: 1.5em }
.f2r { font-size: 2em }
.f2-5r { font-size: 2.5em }
.f3r { font-size: 3em }
.f4r { font-size: 4em }
.f5r { font-size: 5em }
.f6r { font-size: 6em }
.f7r { font-size: 7em }
.f8r { font-size: 8em }

.f3r-hover:hover { font-size: 3em }

.ff-bd { font-family: 'GothamRounded-Bold'}
.ff-md { font-family: 'GothamRounded-Medium'}
.ff-bk { font-family: 'GothamRounded-Book'}
.ff-Li { font-family: 'GothamRounded-Light'}

.lh-title { line-height: 1.4 } /*verified*/
.lh-copy { line-height: 1.1 } /*verified*/
.lh-close { line-height: 0.7 } /*verified*/
.lh-0 { line-height: 0 }

.a-visited-w { color: var(--xisy-w) }
.a-visited-w:visited { color: var(--xisy-w) }

.a-visited-b { color: var(--xisy-b) }
.a-visited-b:visited { color: var(--xisy-b) }

@media screen and (min-width: 48em){
  .f0-5r-ns { font-size: 0.5em }
  .f0-75r-ns { font-size: 0.75em }
  .f1r-ns { font-size: 1em }
  .f1-5r-ns { font-size: 1.5em }
  .f2r-ns { font-size: 2em }
  .f2-5r-ns { font-size: 2.5em }
  .f3r-ns { font-size: 3em }
  .f4r-ns { font-size: 4em }
  .f5r-ns { font-size: 5em }
  .f6r-ns { font-size: 6em }

  .lh-title-ns { line-height: 1.4 } /*verified*/
  .lh-copy-ns { line-height: 1.1 } /*verified*/
  .lh-close-ns { line-height: 0.7 } /*verified*/
}

@media screen and (min-width: 48em) and (max-width: 64em){
  .f0-5r-m { font-size: 0.5em }
  .f0-75r-m { font-size: 0.75em }
  .f1r-m { font-size: 1em }
  .f1-5r-m { font-size: 1.5em }
  .f2r-m { font-size: 2em }
  .f2-5r-m { font-size: 2.5em }
  .f3r-m { font-size: 3em }
  .f4r-m { font-size: 4em }
  .f5r-m { font-size: 5em }
  .f6r-m { font-size: 6em }
}

@media screen and (min-width: 64em)  {
  .f0-5r-l { font-size: 0.5em }
  .f0-75r-l { font-size: 0.75em }
  .f1r-l { font-size: 1em }
  .f1-5r-l { font-size: 1.5em }
  .f2r-l { font-size: 2em }
  .f2-5r-l { font-size: 2.5em }
  .f3r-l { font-size: 3em }
  .f4r-l { font-size: 4em }
  .f5r-l { font-size: 5em }
  .f6r-l { font-size: 6em }

  .lh-title-l { line-height: 1.4 } /*verified*/
  .lh-copy-l { line-height: 1.1 } /*verified*/
  .lh-close-l { line-height: 0.7 } /*verified*/

  .font-adjust{
    font-size: 1.3rem 
  }
}

@media screen and (min-width: 75em) {
  .font-adjust{
    font-size: 1.4rem 
  }
  .f-headline-xl {
    font-size: 6em ;
  }
  .f-subheadline-xl {
    font-size: 5em ;
  }
}

@media screen and (min-width: 145em) {
  .f-9-xxl { font-size: 9em ; }
  .f-4-xxl { font-size: 4em ; }

  .font-adjust{
    font-size: 2rem 
  }
}


