/* BORDER COLORS */
.b-xisy-b { border-color: var(--xisy-b); }
.b-xisy-db { border-color: var(--xisy-db); }
.b-xisy-r { border-color: var(--xisy-r); }
.b-xisy-w { border-color: var(--xisy-w); }
.b-green { border-color: VAR(--green); }
.b-coral { border-color: VAR(--coral); }
.b-dark-grey { border-color: VAR(--dark-grey); }
.b-light-grey { border-color: rgb(122, 122, 122); }
.b-white { border-color:white }


/* BACKGROUND COLORS */
.bg-xisy-b { background-color: var(--xisy-b); }
.bg-xisy-db { background-color: var(--xisy-db); }
.bg-xisy-r { background-color: var(--xisy-r); }
.bg-xisy-w { background-color: var(--xisy-w); }
.bg-popup { background-color: rgba(31,31,31,0.4); }
.bg-green { background-color: VAR(--green); }
.bg-coral { background-color: VAR(--coral); }
.bg-dark-grey { background-color:VAR(--dark-grey); }


/* HOVER COLORS */
.hover-xisy-b { color: var(--xisy-b); }
.hover-xisy-db { color: var(--xisy-db); }
.hover-xisy-r { color: var(--xisy-r); }
.hover-xisy-w { color: var(--xisy-w); }

/* COLORS */
.xisy-b { color: var(--xisy-b); }
.xisy-db { color: var(--xisy-db); }
.xisy-r { color: var(--xisy-r); }
.xisy-w { color: var(--xisy-w); }
.xisy-w-80 { color: rgba(255,255,255,0.8); }
.green { color: var(--green); }
.coral { color: var(--coral); }
.dark-grey { color: var(--dark-grey); }

/* @media screen and (min-width: 64em) and (max-width: 75em) {
  .bg-popup-ns { background-color: rgba(31,31,31,0.4); }
} */

@media screen and (min-width: 48em)  {
  .b-xisy-b-m { border-color: var(--xisy-b); }
  .b-xisy-db-m { border-color: var(--xisy-db); }
  .b-xisy-r-m { border-color: var(--xisy-r); }
  .b-xisy-w-m { border-color: var(--xisy-w); }

  .bg-xisy-b-m { background-color: var(--xisy-b); }
  .bg-xisy-db-m { background-color: var(--xisy-db); }
  .bg-xisy-r-m { background-color: var(--xisy-r); }
  .bg-xisy-w-m { background-color: var(--xisy-w); }

}
@media screen and (min-width: 64em) {
  .bg-popup-l { background-color: rgba(31,31,31,0.6); }
  .xisy-w-l { color: var(--xisy-w); }
  .bg-xisy-b-l { background-color: var(--xisy-b); }
  .bg-xisy-db-l { background-color: var(--xisy-db); }
  .bg-xisy-r-l { background-color: var(--xisy-r); }
  .bg-xisy-w-l { background-color: var(--xisy-w); }
}


