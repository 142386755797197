
/* Media Queries */
@custom-media --breakpoint-not-small screen and (min-width: 48em);
@custom-media --breakpoint-medium screen and (min-width: 48em) and (max-width: 64em);
/* @custom-media --breakpoint-large screen and (min-width: 64em) and (max-width: 75em); */
@custom-media --breakpoint-large screen and (min-width: 64em);
@custom-media --breakpoint-xlarge screen and (min-width: 75em);

:root {
  --font-body     : 'GothamRounded-Book', sans-serif;
  /* --font-body     : 'ProximaNovaSoft-Regular', sans-serif; 
  --font-serif    : 'ProximaNovaSoft-Regular', serif;*/
  --font-serif    : 'GothamRounded-Book', serif;
  --font-sans     : 'GothamRounded-Book', serif;
  --font-headline : 'GothamRounded-Bold', sans-serif;

  /* Natural Colors */
  --black       : #000;
  --near-black    : #111;
  --dark-gray     : #333;
  --mid-gray      : #555;
  --gray          : #777;
  --silver        : #999;
  --light-silver  : #aaa;
  --moon-gray     : #ccc;
  --light-gray    : #eee;
  --near-white    : #f4f4f4;
  --white         : #fff;

  /* Custom Colors */
  --xisy-b : #00AEEF;
  --xisy-r : #ED1C24;
  --xisy-w : #FFFFFF;
  --xisy-db : #232176;
  --green : rgb(0,185,141);
  --coral: rgb(255,144,135);
  --dark-grey: #1F1F1F;

  /* Colors */
  --dark-red      : #e7040f;
  --red           : #ff4136;
  --light-red     : #ff725c;
  --orange        : #ff6300;
  --gold          : #ffb700;
  --yellow        : #ffd700;
  --light-yellow  : #fbf1a9;
  --purple        : #5e2ca5;
  --light-purple  : #a463f2;
  --dark-pink     : #d5008f;
  --hot-pink      : #ff41b4;
  --pink          : #ff80cc;
  --light-pink    : #ffa3d7;
  --dark-green    : #137752;
  --green         : #19a974;
  --light-green   : #9eebcf;
  --navy          : #001b44;
  --dark-blue     : #00449e;
  --blue          : #357edd;
  --light-blue    : #96ccff;
  --lightest-blue : #cdecff;
  --washed-blue   : #f6fffe;
  --washed-green  : #e8fdf5;
  --washed-yellow : #fffceb;
  --washed-red    : #ffdfdf;
}
