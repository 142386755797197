.mha { margin-left: auto; margin-right: auto }
.mb-3 { margin-bottom: 3rem; }
.mb55 { margin-bottom: 6rem; }

.pa45 { padding: 2.5rem }
.ph2-5 { padding: 0.7rem }
.pb0-5 { padding-bottom: 0.05rem}

.mr-a { margin-right: auto }

.top-50px { top: 50px }

.top-50 { top: 50%; }
.left-50 { left: 50%; }
.transform-none { transform: none; }
.transform-middle { transform: translateY(-50%) translateX(-50%); }
.mv55 { margin-top: 6rem; margin-bottom: 6rem; }
.mt55 { margin-top: 6rem; }
.right-3 { right: 3rem; }
.ml525 { margin-left: 5rem; }
.ml55 { margin-left: 6rem; }

/*
  ipad 48 rem
  ipad pro 64 rem
  iphone x 23.6 rem
  iphone plus 25.9
  Pixel 2XL 25.7
  Galaxy s5 22.5
  iphone 5 20
*/

@media screen and (min-width: 48em){
  .mha-ns { margin-left: auto ; margin-right: auto  }
  .mh0-ns { margin-left: 0 ; margin-right: 0  }
  .mr-a-ns { margin-right: auto }
  .mr0-ns { margin-right: 0 }
  .ml0-ns { margin-left: 0 }
  .ml1-ns { margin-left: 0.25rem }
  .ml2-ns { margin-left: 0.5rem }
  .mb55-ns { margin-bottom: 6rem; }
  .mv55-ns { margin-top: 6rem; margin-bottom: 6rem; }
  .mt55-ns { margin-top: 6rem; }

  .transform-none-ns { transform: none; }
  .transform-middle-ns { transform: translateY(-50%) translateX(-50%); }
  .top-50-ns { top: 50%; }
  .left-50-ns { left: 50%; }

  .top-0 { top: 0% }
  .top--50 { top: -50% }
  .top--100 { top: -100% }

}

@media screen and (min-width: 48em) and (max-width: 64em) {
  .mha-m { margin-left: auto; margin-right: auto  }
  .mr0-m { margin-right: 0 }
  .ml2-m { margin-left: 0.5rem }
  .transform-none-m { transform: none; }
}

@media screen and (min-width: 64em) {
  .mha-l { margin-left: auto ; margin-right: auto  }
  .mr0-l { margin-right: 0 }
  .ml2-l { margin-left: 0.5rem }
  .transform-none-l { transform: none; }
  .mv55-l { margin-top: 6rem; margin-bottom: 6rem; }
  .mt55-l { margin-top: 6rem; }
}

@media screen and (min-width: 75em) {
  .mha-xl { margin-left: auto ; margin-right: auto  }
}



