/* mobile */
.container-thin, 
.container-medium,
.container {
  padding-right: 3rem;
  padding-left: 3rem;
}

.container-thin-l,
.container-thin {
  max-width: 1091px;
}

.container-medium-l,
.container-medium {
  max-width: 1344px;
}

.flex-grow-3 { flex-grow: 3; }
.flex-grow-2 { flex-grow: 2; }
.flex-grow-1 { flex-grow: 1; }
.flex-grow-0 { flex-grow: 0; }

.br-bottom {
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.br {
  border-radius: 0.4rem;
}

.bw-2 {
  border-width: 2px;
}

/* tablet */
@media screen and (min-width: 48em) {
  .flex-grow-1-ns { flex-grow: 1; }
  .flex-grow-0-ns { flex-grow: 0; }
}

/* ipad Pro up */
@media screen and (min-width: 64em) {
  .container-medium-l {
    padding-left: 0;
    padding-right: 0;
  }
  .container-thin-l {
    padding-left: 0;
    padding-right: 0;
  }
}

/* large web */
@media screen and (min-width: 100em) {
  .container {
    padding-left: 0;
    padding-right: 0;
  }

  .container-thin {
    padding-left: 0;
    padding-right: 0;
  }

  .container-medium {
    padding-left: 0;
    padding-right: 0;
  }
}

