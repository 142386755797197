
/* @import url("//hello.myfonts.net/count/274f15"); */
/* @font-face {font-family: 'ProximaNovaSoft-Regular';src: url('../assets/fonts/274F15_0_0.eot');src: url('../assets/fonts/274F15_0_0.eot?#iefix') format('embedded-opentype'),url('../assets/fonts/274F15_0_0.woff') format('woff'),url('../assets/fonts/274F15_0_0.ttf') format('truetype');}  */
@font-face {font-family: 'GothamRounded-Bold';src: url('../assets/fonts/GothamRounded-Bold.otf');src: url('../assets/fonts/GothamRounded-Bold.ttf') format('truetype');} 
@font-face {font-family: 'GothamRounded-Medium';src: url('../assets/fonts/GothamRounded-Medium.otf');src: url('../assets/fonts/GothamRounded-Medium.ttf') format('truetype');} 
@font-face {font-family: 'GothamRounded-Book';src: url('../assets/fonts/GothamRounded-Book.otf');src: url('../assets/fonts/GothamRounded-Book.ttf') format('truetype');} 
@font-face {font-family: 'GothamRounded-Light';src: url('../assets/fonts/GothamRounded-Light.otf');src: url('../assets/fonts/GothamRounded-Light.ttf') format('truetype');} 
@import '~antd/dist/antd.css';
@import './tachyons.css';


/* Extend Tachyon Classes */
@import './extend/_colors.css';
@import './extend/_type-scale.css';
@import './extend/_widths.css';
@import './extend/_heights.css';
@import './extend/_container.css';
@import './extend/_layout.css';
@import './vars.css';

/* Addons */
@import './addons/or.css';
@import './addons/spacing.css';

html,
body {
  font-family: var(--font-body);
  font-size: 8px; /* 12 on purpose - adjusting this changes all REM values */
  line-height: 1.625em;
  color: var(--xisy-db);
  background-color: #1F1F1F;
}

a, a:hover { 
  text-decoration: none;
 }
a, a:visited  { 
  color: var(--xisy-db); 
}
a:hover { 
  filter: brightness(85%); 
}

 h1, h2, h3, h4, h5, h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: var(--font-headline);
  font-weight: 100;
}

button {
  border-radius: .25em;
  transition: opacity .15s ease-in;
  opacity: 1;
  font-size: 1.5em;
  margin-bottom: .5rem;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: .5em;
  padding-bottom: .5em;
  width: 12em;
  cursor: pointer;
}

button:hover{
  opacity:.7;
}

.primary {
  background-color: var(--xisy-b);
  border-color: var(--xisy-b);
  color: #fff;
}

.secondary-r,
.secondary {
  background-color: white;
  border-color: white;
  color: var(--xisy-b);
}

.secondary-r {
  color :var(--xisy-r);
}

.text-shadow-1 { text-shadow: 1px 1px 5px var(--black-30); }
.text-shadow-2 { text-shadow: 4px 4px 4px var(--black-40); }

.underline-dotted {border-bottom: 1px dotted; padding-bottom: 0.2em;}

.trans { 
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease-in;
  transition-delay: 0s
 }

 .trans-slow { 
  transition-property: all;
  transition-duration: 0.4s;
  transition-timing-function: ease-in;
  transition-delay: 0s
 }

 /*
  ipad 48 rem
  ipad pro 64 rem
  iphone x 23.6 rem
  iphone plus 25.9
  Pixel 2XL 25.7
  Galaxy s5 22.5
  iphone 5 20
*/


@media screen and (min-width: 65em) {
  .shadow-hover-l {  position: relative; transition: all .5s cubic-bezier( .165, .84, .44, 1 ); }
  .shadow-hover-l::after { content: ''; box-shadow: 0 0 16px 2px rgba( 0, 0, 0, .2 ); border-radius: inherit; opacity: 0; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1; transition: opacity .5s cubic-bezier( .165, .84, .44, 1 ); }
  .shadow-hover-l:hover::after, .shadow-hover-l:focus::after { opacity: 1; }
}

.year:hover {
  font-size: 1.2em;
}

.album:hover {
  border-color: #fff;
  border-style: solid;
  border-width: 2px;
}

.albumFade {
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC44Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.7) 0%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
    background: -o-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
    background: -ms-linear-gradient(top,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom,  rgba(0,0,0,0.7) 0%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc000000', endColorstr='#00000000',GradientType=0 );
    position:absolute;
    height:100%;
    width:100%;
    top:0px;
}

.horizontal-scroll-wrapper{
  -webkit-overflow-scrolling: touch;
}
.horizontal-scroll-wrapper > div{
  flex: 0 0 auto;
}

.file-dl {
  opacity: 0.1;
}

.file-dl:hover {
  opacity: 1;
}